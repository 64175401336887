/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, Subtitle, PriceWrap } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="pb--80 pl--0 pt--80" name={"introduction"}>
          
          <ColumnWrap className="column__flex el--1 pb--40 pl--40 pr--40 pt--60 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2 --center mt--10 pl--0 pr--0" anim={"2"} animS={"2"}>
              
              <Title className="title-box fs--62 w--300 ls--002 lh--14" style={{"maxWidth":1082}} content={"Handcrafting elegant custom wooden furniture"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--cColor2" href={"/en/katalog"} content={"Catalog"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-wpsew6 --border4 bg--center --full pb--0 pt--0" name={"introduction-2"} border={"4"} parallax={false} fullscreen={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/98/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/98/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/98/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/98/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/98/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/98/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/98/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/98/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --right el--1 --full flex--bottom" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box ff--1" content={"Table & chairs ELEANOR — $1200"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"information-1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2 pb--80 pt--60" anim={"2"} animS={"2"}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"We make custom wooden furniture in Brno using quality – responsibly sourced – natural materials."}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"navigation"} style={{"backgroundColor":"var(--color-custom-2)"}} border={null}>
          
          <ColumnWrap className="column__flex --right el--2 pb--60 pl--06 pr--06 flex--top" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim3 --anim-s2 --left" anim={"3"} animS={"2"} style={{"maxWidth":776}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/98/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--left mb--0 pb--0 pl--0 pr--0" content={"Kitchens"}>
              </Subtitle>

              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/e1da5bad0fe24d3cb98a752471ee4faf.svg"} svg={false} href={"#"} sizes={"100vw"} style={{"maxWidth":300}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s2 --left mt--80" anim={"2"} animS={"2"} style={{"maxWidth":776}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/98/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--left w--300 mb--0 pb--0 pl--0 pr--0" content={"Living room furniture"}>
              </Subtitle>

              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/e1da5bad0fe24d3cb98a752471ee4faf.svg"} svg={false} href={"#"} sizes={"100vw"} style={{"maxWidth":300}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"pricelist-1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2 pb--80 pt--60" anim={"2"} animS={"2"}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"We make custom wooden furniture in Brno using quality – responsibly sourced – natural materials."}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mb--0 pb--0 flex--center" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s2" anim={"5"} animS={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/98/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim6 --anim-s2" anim={"6"} animS={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/98/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":470}} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s2" anim={"4"} animS={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/98/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/i/template/98/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/98/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/98/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/98/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/98/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"pricelist-2"}>
          
          <ColumnWrap className="column__flex el--1 pb--40" border={null} columns={"1"}>
            
            <ColumnWrapper >
              
              <PriceWrap >
                
                <ColumnWrapper border={null}>
                  
                  <Text className="text-box" content={"Inbuilt wardrobes"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"starting at $1199"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap >
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Bathroom furniture"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"starting at $1199"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap >
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Office furniture"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"starting at $1199"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap >
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Kitchens"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"starting at $1199"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"product-catalog"} style={{"backgroundColor":"var(--color-custom-2)"}} border={null} layout={"l24"}>
          
          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--right mt--0" content={"<span style='color: var(--color-dominant)'>Product catalog</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--left mt--0" href={"/en/katalog"} content={"Enter"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pl--0 pt--50" name={"footer"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --right el--3 pb--40 pt--40 flex--bottom" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"Merbau"}>
              </Title>

              <Text className="text-box text-box--left fs--16" style={{"maxWidth":351}} content={"We make custom wooden furniture in Brno using quality – responsibly sourced – natural materials."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box title-box--left fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"Contact"}>
              </Title>

              <Text className="text-box text-box--left" content={"Finch Street 24"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"NJ 07712"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"510-851-40X4"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Button className="btn-box btn-box--hvr2 btn-box--sbtn1 btn-box--cColor2 btn-box--filling2 fs--18" href={"#uvod"} content={"<span style=\"color: var(--color-supplementary);\">Back to the top</span>"}>
              </Button>

              <Text className="text-box text-box--right mt--0" content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}